import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'

import Header from '../components/header-main'
import ExitIntent from '../components/ExitIntent'

import '../styles/index.css'

// process.env.GATSBY_ACTIVE_ENV === 'production' && typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag('event', 'conversion', {'send_to': 'AW-XXXX'})


const PageWrapper = styled.div`
  background: #69D49F;
  min-height: 100vh;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 900px;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 90vh;
  padding: 40px;
  overflow: scroll;
  background: white;
`

const ContentTitle = styled.h1`
  font-size: 26px;
`

const ContentDate = styled.h2`
  font-size: 13px;
`

const Content = styled.div`
  font-size: 15px;

  p {
    margin-bottom: 12px;
  }

  li {
    margin-bottom: 8px;
  }

  h4 {
    margin-top: 40px;
  }
`

function LegalTemplate({ data: { mdx, site } }) {
  return (
    <PageWrapper>
      <Header siteTitle={site.siteMetadata.title} />
      <ContentWrapper>
        <Container>
          <ContentTitle>{mdx.frontmatter.title}</ContentTitle>
          <ContentDate>{mdx.frontmatter.date}</ContentDate>
          <Content>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </Content>
        </Container>
      </ContentWrapper>
      <ExitIntent />
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query LegalQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
      }
    },
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default LegalTemplate
